import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name:"SignUp",

    data () {
        return {
            valid: false,
            isLoading: false,
            firstName: '',
            firstNameRules: [
                v => !!this.validateRequired(v) || 'first name is required'
            ],

            lastName: '',
            lastNameRules: [
                v => !!this.validateRequired(v) || 'Last name is required'
            ],

            job: '',
            jobRules: [
                v => !!this.validateRequired(v) || 'Job is required',
            ],

            company: '',
            companyRules: [
                v => !!this.validateRequired(v) || 'Company is required',
            ],

            email: '',
            emailRules: [
                v => !!this.validateRequired(v) || 'Email is required',
                v => this.validateEmailFormat(v) || 'Email must be valid',
            ],

            password: '',
            passwordRules: [
                v => !!this.validateRequired(v) || 'password is required',
                // v => !!v.length >= 6 || 'password should be atleast 6 characters',
            ],

            confirmPassword: '',
            confirmPasswordRules: [
                v => !!this.validateRequired(v) || 'Confirm password is required',
                v => this.confirmPasswordValidation(v) || 'confirm password is not equal to password'
            ],
        }
    },

    computed: {
        ...mapGetters('notification', ['config']),
    },

    methods: {
        ...mapActions('auth', ['register']),
        ...mapMutations('notification', ['remove', 'notify']),

        async signUp() {
            var newUser = {
                firstName: this.firstName,
                lastName: this.lastName,
                job: this.job,
                company: this.company,
                email: this.email,
                password: this.password
            }

            if (this.validateNewUser(newUser)) {
                this.isLoading = true
                try {
                    await this.register(newUser);
                    this.$router.push({ name: 'signin' })
                    this.notify({message: '.سوف تتلقى رسالة التحقق بالبريد الإلكتروني. يرجى التحقق قبل المتابعة', show: true})
                } catch (e) {
                    this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
                }
                this.isLoading = false
            } else {
                this.notify({message: 'هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.', show: true})
            }
        },

        validateNewUser(newUser) {
            return this.validateRequired(newUser.firstName) &&
                this.validateRequired(newUser.lastName) &&
                this.validateRequired(newUser.job) &&
                this.validateRequired(newUser.company) &&
                this.validateRequired(newUser.password) &&
                this.validateRequired(newUser.email) &&
                this.validateEmailFormat(newUser.email) &&
                this.confirmPasswordValidation(this.confirmPassword)
        },

        confirmPasswordValidation(confirmPassword) {
            return this.password === confirmPassword
        },

        validateRequired(value) {
            return value.length > 0
        },

        validateEmailFormat(email) {
            return /.+@.+/.test(email)
        }
    }
}